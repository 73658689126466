export function buttonTheme(colors) {
    //language=SCSS
    return `& {
        background: ${colors.background};
        color: ${colors.text};
        border-color: ${colors.border};
        white-space: normal;
        cursor: pointer;
        outline: unset;
        box-shadow: none !important;
        
        &:hover, &.test-hover {
            background: ${colors.backgroundHover || colors.background};
            color: ${colors.textHover || colors.text};
        }
        &:focus, &.test-focus {
            outline: unset;
            box-shadow: unset;
            background: ${colors.backgroundFocus || colors.background};
            color: ${colors.textFocus};
        }
        &:hover:active, &:active, &.test-active, &:not(:disabled):not(.disabled):active {
            color: ${colors.textHover};
            background: ${colors.backgroundActive || colors.backgroundHover || colors.background};
            outline: unset;
            border-color: ${colors.border};
        }
    }`;
}
