import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { medias } from '../../styles/medias';

class CenteredView extends React.Component {
    static propTypes = {
        width: PropTypes.number,
    };
    
    render() {
        const props = this.props;
        return (
            <div className={props.className}>
                <div className="centered-view-content" style={{maxWidth: props.width || 900}}>
                    {props.children}
                </div>
            </div>
        );
    }
    
    shouldComponentUpdate() {
        // Layout Component with children => should always update
        return true;
    }
}

//language=SCSS
CenteredView = Styled(CenteredView)`
& {
    width: 100%;
    padding: 0;
    
    > .centered-view-content {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    
        @media print {
            max-width: 100% !important;
            padding: 0;
            margin: 0;
            border: none;
            background: none;
            box-shadow: none;
        }
    }
}
`;

export { CenteredView };
