import React from 'react';
import Styled from 'styled-components';
import { colors, desaturate } from '../../styles/colors';
import { CenteredView } from './centered-view';

let Footer = (props) => (
	<div className={props.className}>
		<CenteredView>
			&copy; 2016-2020 Xureilab
		</CenteredView>
	</div>
);

//language=SCSS
Footer = Styled(Footer)`
& {
	position: relative;
	height: 50px;
	line-height: 30px;
	background: ${colors.lightergray};
	padding: 10px 0;
	text-align: center;
}
`;

export { Footer };
