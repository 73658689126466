import React from 'react';
import Styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Button } from '../buttons/button';
import { Logo } from '../static/logo';

let Header = (props) => (
	<div className={props.className}>
		<div className="logo-wrapper">
			<a href="/">
				<Logo/>
			</a>
		</div>
	</div>
);

//language=SCSS
Header = Styled(Header)`
& {
	position: sticky;
	top: 0;
	height: 50px;
	z-index: 100;
	background: ${colors.primary};
	padding: 9px;
	
	.logo-wrapper {
		position: relative;
		top: -1px;
		color: #fff !important;
	}
}
`;

export { Header };
