import React from 'react';
import { Header } from './header';
import { Footer } from './footer';
import Styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Helmet } from 'react-helmet';

let MainLayout = (props) => (
	<div className={props.className}>
		<Helmet>
			{/* Global site tag (gtag.js) - Google Analytics
			<script async src="https://www.googletagmanager.com/gtag/js?id=UA-89637767-5"/>
			<script>{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'UA-89637767-5');
			`}</script>*/}
			
			<meta charSet="utf-8" />
			<link href="https://fonts.googleapis.com/css?family=Comfortaa:300,400,700" rel="stylesheet" type="text/css"/>
		</Helmet>
		<Header/>
		
		<div className="main-content">
			{props.children}
		</div>
		
		<Footer/>
	</div>
);

//language=SCSS
MainLayout = Styled(MainLayout)`
& {
	.main-content {
		display: block;
		position: relative;
		min-height: 90vh;
	}
}
`;

export { MainLayout };
