import React from 'react'; //eslint-disable-line no-unused-vars
import Styled from 'styled-components';

class Logo extends React.Component {
    static propTypes = {};
    
    render() {
        const props = this.props;
        return (
            <div className={props.className}>
                {/*<span className="brand-text">Xureilab</span>*/}
                <img src="/images/logo.png" alt="xureiLab"/>
            </div>
        );
    }
    
    shouldComponentUpdate() {
        return false;
    }
}

//language=SCSS
Logo = Styled(Logo)`
& {
    display: inline-block;
    .brand-text {
        display: inline-block;
        font-family: 'Comfortaa', cursive;
        font-size: 24px;
        color: #fff;
        text-transform: none;
    }
    
    img {
        vertical-align: bottom;
        margin-right: 0.7em;
        height: 36px;
    }
}
`;

export { Logo };
