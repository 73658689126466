import { Button } from '../buttons/button';
import React from 'react';
import Styled from 'styled-components';
import { colors } from '../../styles/colors';
import { medias } from '../../styles/medias';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

let Out = (props) => {
	return (
		<section className={`${props.className} above-fold`}>
			<div className="bg">
				{props.image && (
					<Img fluid={props.image} />
				)}
			</div>
		    <div className="content">
			    {props.children}
		    </div>
		</section>
	);
};

//language=SCSS
Out = Styled(Out)`
& {
	height: 85vh;
	overflow: hidden;
    @media screen and (${medias.mdMin}) {
		height: 70vh;
    }
    
    //background-image: url(/bg_bees_black_sm.jpg);
    //@media screen and (${medias.mdMin}) {
    //    background-image: url(/bg_bees_black.jpg);
    //}
    background-size: cover;
    background-position: center;
    
	position: relative;
	
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
		top: 0;
		z-index: 2;
	}
	
	> * {
		position: relative;
		z-index: 3;
	}
	
	> .bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		
		.gatsby-image-wrapper {
			position: relative;
			min-height: 100%;
			transform: translate(0, -50%);
			top: 50%;
			overflow: hidden;
		}
	}
	
	> .content {
		position: absolute;
		width: 100%;
		z-index: 2;
		top: 20%;
		padding: 0 5px;
	    @media screen and (${medias.smMin}) {
			top: 35%;
	        min-height: 70vh;
	    }
		text-align: center;
	}
	color: #fff;
	text-shadow: 0 0 3px #000, 0 0 6px #000;
	
	h1 {
		margin-bottom: 20px;
		color: #fff;
	}
	
	.main-description {
		text-shadow: 0 0 3px #000;
	}
}
`;

export default Out;
