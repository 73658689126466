import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { MainLayout } from '../components/layout/main-layout';
import AboveFold from '../components/layout/above-fold';
import { CenteredView } from '../components/layout/centered-view';
import Styled from 'styled-components';

let Out = function BlogTemplate(props) {
	const data = props.data; /* this prop will be injected by the GraphQL query below. */
	console.log(data);
	const { markdownRemark } = data; // data.markdownRemark holds your post data
	const { frontmatter, html, timeToRead } = markdownRemark;
	const featuredImage = frontmatter.featuredImage;
	
	return (
		<div className={props.className}>
			<Helmet>
				<link rel="canonical" href="https://blog.xureilab.com" />
				{frontmatter.summary && (<title>{frontmatter.title} | Xureilab</title>)}
				{frontmatter.summary && (<meta name="description" content={frontmatter.summary}/>)}
				{featuredImage && (<meta property="og:image" content={`https://blog.xureilab.com${featuredImage.childImageSharp.fluid.src}`} />)}
				<meta property="og:title" content={`${frontmatter.title} | Xureilab`} />
			</Helmet>
			<MainLayout>
				{/*<AboveFold image={featuredImage.childImageSharp.fluid}>*/}
				{/*	<h1 className="blog-title">{frontmatter.title}</h1>*/}
				{/*	<p className="main-description">*/}
				{/*		{frontmatter.summary}*/}
				{/*	</p>*/}
				{/*</AboveFold>*/}
				<div className="blog-post-container">
					<br/>
					<CenteredView width={1024}>
						<p>
							{frontmatter.date} by {frontmatter.author || 'Olivier Bourdoux'} <br/>
							Reading time: {timeToRead}min
						</p>
						<div className="blog-post">
							<div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }}/>
						</div>
					</CenteredView>
				</div>
				<br/>
				<br/>
				<br/>
			</MainLayout>
		</div>
	)
};

//language=SCSS
Out = Styled(Out)`
& {
	.above-fold {
        &:before {
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
        }
    }
	
    .blog-post-container {
        padding: 0 20px;
    }
	
	.blog-title {
        width: 40%;
	 	margin: 0 auto;
		margin-bottom: 1em;
	}
	
	.main-description {
		font-size: 1.1em;
	}
	
	.blog-post {
		font-size: 1.0em;
		
		h2 {
			font-size: 2.2rem;
		}
        h3 {
            font-size: 1.8rem;
        }
		
		h1, h2, h3, h4, h5, h6 {
			font-family: monospace;
			font-weight: 700;
		}
		
		h2, h3, h4, h5, h6 {
			margin-top: 2em;
			margin-bottom: 0.7em;
		}
		
		a {
			text-decoration: underline;
		}
		
		pre {
			background: #2B2B2B;
			color: #fff;
			padding: 20px 32px;
			font-size: 16px;
		}
		
		code.language-text {
			background: rgba(0,0,0, 0.1);
			color: #272822;
			padding: 0 5px;
		}
		
		hr {
			margin: 75px 0;
		}
		
		img {
			max-width: 100%;
		}

        //h4, h4 ~ * {
        //    margin-left: 48px;
        //}
        //h3, h3 ~ * {
        //    margin-left: 32px;
        //}
	    //h2, h2 ~ * {
	    //    margin-left: 16px;
	    //}
	}
	
	/*.blog-post-content {
        h1:first-of-type {
	        display: none;
        }
	}*/
}
`;

export default Out;

//language=GraphQL
export const pageQuery = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                date(formatString: "YYYY-MM-DD")
                path
                title
	            summary
                featuredImage {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            timeToRead
        }
    }
`;
