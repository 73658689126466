import Color from 'color';

export const colors = {
	white: '#fff',
	nearWhite: '#f8f9fa',
	lightergray: '#eee',
	lightgray: '#ccc',
	silver: '#999',
	darkgray: '#777',
	darkergray: '#444',
	black: '#111',
	
	primary: '#16A085',
	secondary: '#C05B0E',
	danger: '#B31616',
	dark: '#132231',
};

export function lighten(color, amount) {
	const lab = Color(color).lab();
	lab.color[0] += amount;
	const out = lab.rgb();
	return rgbToHex(...out.color);
}

export function desaturate(color, amount) {
	let lab = Color(color);
	lab = lab.desaturate(amount/100.0);
	const out = lab.rgb();
	return rgbToHex(...out.color);
}

export function componentToHex(c) {
	const hex = Math.round(c).toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
}

export function rgbToHex(r, g, b) {
	return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

export function rgba(color, ratio) {
	color = Color(color);
	const out = color.rgb().color;
	return `rgba(${out[0]},${out[1]},${out[2]}, ${ratio})`;
}
